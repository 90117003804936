<template>
  <div style="width: 100%;height: 100vh;background-color: rgb(8, 20, 32);overflow: hidden">
    <!--  顶部-->
    <div style="position: absolute;left: 50%;margin-left: -30%">
      <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/show_material.png" style="width: 80%">
    </div>
    <div style="height: 100px;display: flex;justify-content: space-between">
      <!--      日期选择-->
      <div style="padding-top: 15px;padding-left: 50px">
        <el-date-picker
            v-model="time"
            type="month"
            placeholder="选择日期"
            format="YYYY-MM"
            value-format="YYYY-MM"
            size="mini"
            style="color: #fff"
            @change="renewData"
        >
        </el-date-picker>

      </div>

      <!--      时间-->
      <div id="timestyle">
        {{ timenow }}
      </div>
    </div>
    <div style="position:absolute;top: 1.8vh;left: 20vh;">
      <div style="color: #FF0000;margin-bottom: 1vh;font-size: 1.5em" v-if="radio1 === '1'" @click="radio1 = '1'">开
        累
      </div>
      <div style="color: #FFFFFF;margin-bottom: 1vh;font-size: 1.5em" v-if="radio1 === '2'" @click="radio1 = '1'">开
        累
      </div>
      <div style="color: #FF0000;font-size: 1.5em" v-if="radio1 === '2'" @click="radio1 = '2'">月 度</div>
      <div style="color: #FFFFFF;font-size: 1.5em" v-if="radio1 === '1'" @click="radio1 = '2'">月 度</div>
    </div>
    <!--  开累图表数据-->
    <div>
      <!--第一层-->
      <div style="display: flex;height: 10px;justify-content: space-between;">
        <div
            style="display: flex;justify-content: space-between;width: 100%;height: 30px;line-height: 30px;font-size: 1.5em;padding-left: 10px;margin-left: 50px;margin-top: 15px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
          <span>钢材统计表</span>
          <a href="" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
        </div>
      </div>

      <div style="display: flex">
        <div id="myEchartsSteelFactory"
             style="width: 130vh;height: 35vh;margin-left: 50px;margin-top: 22px;z-index: 3"></div>
        <!--  收发存滚动数据，异常数据标红处理-->
        <div style="position: absolute;right: 1vh;top: 14vh;width: 53vh;height: 37vh;overflow: hidden">
          <div style="height: 40%;">
            <vue3-seamless-scroll :list="list" :step="0.2" :hover="true" :wheel="true" class="scroll">
              <div class="item" v-for="(item, index) in list" :key="index" style="font-size:0;padding-top:1px;color: #FFFFFF">
                {{item}}
                <div id="alldiv" v-if="item.suminventory >= 10" style="background-color: #081420;margin-top:-5px">
                  <div style="width: 35%;height: 40px">
                    {{ item.materialName }}
                  </div>
                  <div style="width: 15%">
                    {{ item.sumincome }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.sumissue }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.suminventory }}
                  </div>
                </div>
                <div id="alldiv" v-if="item.suminventory < 10" style="background-color: #f16f05;margin-top:-5px;">
                  <div style="width: 35%;height: 40px;">
                    {{ item.materialName }}
                  </div>
                  <div style="width: 15%">
                    {{ item.sumincome }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.sumissue }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.suminventory }}
                  </div>
                </div>
              </div>
            </vue3-seamless-scroll>
          </div>
        </div>
      </div>


      <!--第二层-->
      <div style="display: flex;height: 10px;justify-content: space-between;margin-top: 3vh">
        <div
            style="display: flex;justify-content: space-between;width: 100%;height: 30px;line-height: 30px;font-size: 1.5em;padding-left: 10px;margin-left: 50px;margin-top: 15px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
          <span>混凝土统计表</span>
          <a href="" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
        </div>
      </div>

      <div>
        <div id="myEchartsConcreteLedger"
             style="width: 130vh;height: 35vh;margin-left: 50px;margin-top: 22px;z-index: 3"></div>
        <!--  收发存滚动数据，异常数据标红处理-->
        <div style="position: absolute;right: 1vh;top: 55vh;width: 53vh;height: 33vh;overflow: hidden">
          <div style="height: 40%;">
            <vue3-seamless-scroll :list="list2" :step="0.2" :hover="true" :wheel="true" class="scroll">
              <div class="item" v-for="(item, index1) in list2" :key="index1" style="font-size:0;padding-top:1px">
                <div id="alldiv" v-if="item.sumjc >= 10" style="background-color: #081420;margin-top:-5px">
                  <div style="width: 35%;height: 40px">
                    {{ item.name }}
                  </div>
                  <div style="width: 15%">
                    {{ item.sumbugdet }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.sumreal }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.sumjc }}
                  </div>
                </div>
                <div id="alldiv" v-if="item.sumjc < 10" style="background-color: #f16f05;margin-top:-5px;">
                  <div style="width: 35%;height: 40px;">
                    {{ item.name }}
                  </div>
                  <div style="width: 15%">
                    {{ item.sumbugdet }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.sumreal }}
                  </div>
                  <div style="width: 35%;">
                    {{ item.sumjc }}
                  </div>
                </div>
              </div>
            </vue3-seamless-scroll>
          </div>
        </div>
      </div>

    </div>
    <!--组织选择-->
    <div style="position: absolute;bottom: 2vh">
      <div style="margin-left: 5vh;margin-top: 11vh;display: flex;">
        <div class="div1">
          <div class="div2" v-if="organ_name !== '汇总' " @click="getDataByBdst('汇总')">汇总</div>
          <div class="div3" v-if="organ_name === '汇总'" @click="getDataByBdst('汇总')">汇总</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '2标一工区'" @click="getDataByBdst('2标一工区')">二标一工区</div>
          <div class="div3" v-if="organ_name === '2标一工区'" @click="getDataByBdst('2标一工区')">二标一工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '2标二工区'" @click="getDataByBdst('2标二工区')">二标二工区</div>
          <div class="div3" v-if="organ_name === '2标二工区'" @click="getDataByBdst('2标二工区')">二标二工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标一工区'" @click="getDataByBdst('5标一工区')">五标一工区</div>
          <div class="div3" v-if="organ_name === '5标一工区'" @click="getDataByBdst('5标一工区')">五标一工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标二工区'" @click="getDataByBdst('5标二工区')">五标二工区</div>
          <div class="div3" v-if="organ_name === '5标二工区'" @click="getDataByBdst('5标二工区')">五标二工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标三工区'" @click="getDataByBdst('5标三工区')">五标三工区</div>
          <div class="div3" v-if="organ_name === '5标三工区'" @click="getDataByBdst('5标三工区')">五标三工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标四工区'" @click="getDataByBdst('5标四工区')">五标四工区</div>
          <div class="div3" v-if="organ_name === '5标四工区'" @click="getDataByBdst('5标四工区')">五标四工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标五工区'" @click="getDataByBdst('5标五工区')">五标五工区</div>
          <div class="div3" v-if="organ_name === '5标五工区'" @click="getDataByBdst('5标五工区')">五标五工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标六工区'" @click="getDataByBdst('5标六工区')">五标六工区</div>
          <div class="div3" v-if="organ_name === '5标六工区'" @click="getDataByBdst('5标六工区')">五标六工区</div>
        </div>

        <div class="div1">
          <div class="div2" v-if="organ_name !== '5标七工区'" @click="getDataByBdst('5标七工区')">五标七工区</div>
          <div class="div3" v-if="organ_name === '5标七工区'" @click="getDataByBdst('5标七工区')">五标七工区</div>
        </div>
      </div>
    </div>

    <!--详细信息-->
    <div>
      <div v-if="dialogVisible" id="dialog_div" style="z-index: 99">
        <div style="position: absolute;top: 0.5vh;right: 1vh" @click="close_dialog()">
          <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/close.png">
        </div>
        <div id="dialog_detail">
          <div id="myEchartsDetail" style="width: 100%;height: 120%;margin-right: 50px;margin-top: -5vh"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getPreMonth, setNowMonth2, setNowTimesInHole} from "@/utils/time";
import { ElMessage } from 'element-plus'
import {Vue3SeamlessScroll} from "vue3-seamless-scroll";
export default {
  name: "show_material",
  data() {
    return {
      time: '',
      timenow: '',
      organid: '汇总',
      organ_name: '汇总',
      //urlqz: 'https://www.gjegm.ltd/showdata',
      // urlqz: 'http://localhost:8082',
      urlqz: 'https://www.gjegm.ltd/showdata',
      radio1: '1',
      allmonth: '开累',
      dialogVisible: false,
      parameter: '',
      list: [],
      list2: []
    }
  },
  components: {
    Vue3SeamlessScroll
  },
  setup() {
    let list = JSON.parse(sessionStorage.getItem('1'));
    let list2 = JSON.parse(sessionStorage.getItem('2'));
    return {list, list2}
  },
  created() {
    this.time = getPreMonth(setNowMonth2());
    setTimeout(this.getSteelFactory, 100);
    setTimeout(this.getConcreteLedger, 100);
  },
  methods: {
    getDataByBdst(val) {
      this.organ_name = val;
      if (this.organ_name === '汇总') {
        this.organid = '汇总'
      }
      if (this.organ_name === '2标一工区') {
        this.organid = '4d045a0a-c0c2-45cd-b279-62875b03e3f0'
      }
      if (this.organ_name === '2标二工区') {
        this.organid = '38414584-f90a-41c4-8c95-c2fc2127d24c'
      }
      if (this.organ_name === '5标一工区') {
        this.organid = '440adb94-424a-4cd8-a322-9bbb9ec317a5'
      }
      if (this.organ_name === '5标二工区') {
        this.organid = 'ae28a1fb-3c4a-49a7-b3bb-76cfa247c774'
      }
      if (this.organ_name === '5标三工区') {
        this.organid = 'cf5cdcb7-2cb8-451d-a388-ab15d90d33e5'
      }
      if (this.organ_name === '5标四工区') {
        this.organid = 'ac8cdabd-54ca-4e73-81be-e8bc9587b581'
      }
      if (this.organ_name === '5标五工区') {
        this.organid = '428f06db-165a-49f2-bc3b-e9af8bf7386d'
      }
      if (this.organ_name === '5标六工区') {
        this.organid = '417b1f7a-65f9-441c-bd80-311b790d7e2b'
      }
      if (this.organ_name === '5标七工区') {
        this.organid = '69193347-e9be-44c9-974a-3929ff851024'
      }
      this.getSteelFactory();
      this.getConcreteLedger();
    },
    renewData(){
      this.getSteelFactory();
      this.getConcreteLedger();
    },
    getConcreteLedgerXx(){
      this.dialogVisible = true;
      setTimeout(this.getConcreteLedgerXxData, 100);
    },
    getSteelFactoryXx(){
      this.dialogVisible = true;
      setTimeout(this.getSteelFactoryXxData, 100);
    },
    getSteelFactory() {
      sessionStorage.setItem('gj', 'gjgjgjll');
      let that = this;
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsSteelFactory'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      // 判断是开累数据还是月度数据
      let url;
      if(this.allmonth === '开累'){
        url = 'https://www.gjegm.ltd/showdata/czSteelFactory/getSteelFactory';
      }
      if(this.allmonth === '月度'){
        url = 'https://www.gjegm.ltd/showdata/czSteelFactory/getSteelFactoryByMonth';
      }
      this.axios.post(url, (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          sessionStorage.removeItem('1');
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            let flag = response.obj2[1][i];
            y.push(flag);
          }
          this.list = response.obj3;
          sessionStorage.setItem('1', JSON.stringify(this.list));

          // 防止返回图表不重新加载
          document.getElementById('myEchartsSteelFactory').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 吨',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 15
                },
                top: 25,
                left: 5,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 14,
                color: '#fff'
              },

            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '发放',
                data: y,
                type: 'bar',
                barWidth: 30,
                barGap: '5%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'rgb(241,189,5)',
                        fontSize: 13,
                        fontWeight: '50000'
                      },
                    },
                  }
                },
              },
            ]
          });
          myChart.on('click', function(params) {
            that.parameter = params.name;
            that.getSteelFactoryXx();
          });
        }
        if (response.obj.length === 0){
          myChart.hideLoading();    //隐藏加载动画
          ElMessage.error('暂无数据');
        }
      }, {
        organid: this.organid,
        month: this.time
      })
    },
    getConcreteLedger() {
      let that = this;
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsConcreteLedger'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      // 判断是开累数据还是月度数据
      let url;
      if(this.allmonth === '开累'){
        url = 'https://www.gjegm.ltd/showdata/czConcreteLedgerDetail/getConcreteLedger';
      }
      if(this.allmonth === '月度'){
        url = 'https://www.gjegm.ltd/showdata/czConcreteLedgerDetail/getConcreteLedgerByMonth';
      }
      this.axios.post(url, (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          sessionStorage.removeItem('2');
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[1][i]);
          }
          this.list2 = response.obj3;
          sessionStorage.setItem('2', JSON.stringify(this.list2));
          // 防止返回图表不重新加载
          document.getElementById('myEchartsConcreteLedger').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 吨',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 15
                },
                top: 25,
                left: 5,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 14,
                color: '#fff'
              },

            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '用量',
                data: y,
                type: 'bar',
                barWidth: 30,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'rgb(241,189,5)',
                        fontSize: 13,
                        fontWeight: '50000'
                      },
                    },
                  }
                },
              },
            ]
          });
          myChart.on('click', function(params) {
            that.parameter = params.name;
            that.getConcreteLedgerXx();
          });
        }
        if (response.obj.length === 0){
          myChart.hideLoading();    //隐藏加载动画
          ElMessage.error('暂无数据');
        }
      }, {
        organid: this.organid,
        month: this.time
      })
    },
    close_dialog() {
      this.dialogVisible = false
    },
    getConcreteLedgerXxData(){
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例

      const myChart = this.$echarts.init(document.getElementById('myEchartsDetail'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('https://www.gjegm.ltd/showdata/czConcreteLedgerDetail/getConcreteByMonthAndName', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[1][i]);
          }
          // 防止返回图表不重新加载
          document.getElementById('myEchartsDetail').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: this.parameter + '月度使用情况',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 20
                },
                top: 25,
                left: 5,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 18,
                color: '#fff',
              },

            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '',
                data: y,
                type: 'line',
                barWidth: 50,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 30% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'rgb(241,189,5)',
                        fontSize: 50,
                        fontWeight: '50000'
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        concrete_materials_name: this.parameter,
        organid: this.organid
      })
    },
    getSteelFactoryXxData(){
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsDetail'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('https://www.gjegm.ltd/showdata/czSteelFactory/getSteelFactoryByMaterialMonth', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[1][i]);
          }
          // 防止返回图表不重新加载
          document.getElementById('myEchartsDetail').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: this.parameter + '月度发放情况',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 20
                },
                top: 25,
                left: 5,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 18,
                color: '#fff',
              },

            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '',
                data: y,
                type: 'line',
                barWidth: 50,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'rgb(241,189,5)',
                        fontSize: 50,
                        fontWeight: '50000'
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        material: this.parameter
      })
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timenow = setNowTimesInHole(new Date())
    }, 1000);

  },
  watch: {
    radio1(){
      if (this.radio1 === '1'){
        this.allmonth = '开累';
        this.getSteelFactory();
        this.getConcreteLedger();
      }
      if (this.radio1 === '2'){
        this.allmonth = '月度';
        this.getSteelFactory();
        this.getConcreteLedger();
      }
    }
  }
}
</script>

<style scoped>
@import '../../../common/css/show_material.css';

::v-deep .el-input__inner {
  background-color: rgb(12, 37, 48);
  height: 50px;
  line-height: 50px;
  width: 150px;
  font-size: 15px;
  border: 2px solid RGB(48, 84, 95);
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

::v-deep .el-date-editor .el-input__icon:nth-child(1) {
  display: none;
}

#timestyle {
  color: #fff;
  margin-right: 30px;
  margin-top: 25px;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px 0px 20px;
  background-color: rgb(12, 37, 48);
  font-size: 15px;
  border: 2px solid RGB(48, 84, 95);
  border-radius: 10px;
}
</style>